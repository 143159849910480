import { FormInstance } from "sunday-component/node_modules/antd";
import { DatePickerOld } from "sunday-component";
import { some } from "lodash";

import styled, { createGlobalStyle } from "styled-components";
import { Moment } from "sunday-component/node_modules/moment";
import { isMobile } from "src/utils/deviceDetector";
import {
  DEFAULT_DATE_FORMAT,
  isAfterDate,
  isBeforeDate,
  isToday,
} from "../../utils/date";

export const CustomDatePicker = styled(DatePickerOld)`
  border-color: var(--ui-form-input-date_picker-activated-border-color);
  background-color: var(--ui-form-input-date_picker-activated-background-color);
  input {
    color: var(--ui-form-input-date_picker-activated-fields_date_picker-color);
    caret-color: var(
      --ui-form-input-date_picker-activated-fields_date_picker-color
    );
    ::placeholder {
      color: var(
        --ui-form-input-date_picker-activated-placeholder_date_picker-color
      );
    }
  }
  span.sunday-component-picker-suffix {
    color: var(--ui-form-input-date_picker-activated-icon-color);
    stroke: var(--ui-form-input-date_picker-activated-icon-color);
  }

  :hover:not(.sunday-component-picker-disabled) {
    border-color: var(--ui-form-input-date_picker-hover-border-color);
    background-color: var(--ui-form-input-date_picker-hover-background-color);
    input {
      ::placeholder {
        color: var(
          --ui-form-input-date_picker-hover-placeholder_date_picker-color
        );
      }
    }
    span.sunday-component-picker-suffix {
      color: var(--ui-form-input-date_picker-hover-icon-color);
      stroke: var(--ui-form-input-date_picker-hover-icon-color);
    }
  }

  &.sunday-component-picker-focused {
    border-color: var(--ui-form-input-date_picker-focused-border-color);
    background-color: var(--ui-form-input-date_picker-focused-background-color);
    box-shadow: 0 0 0 2px var(--ui-form-input-date_picker-focused-border-shadow);
    input {
      ::placeholder {
        color: var(
          --ui-form-input-date_picker-focused-placeholder_date_picker-color
        );
      }
    }
    span.sunday-component-picker-suffix {
      color: var(--ui-form-input-date_picker-focused-icon-color);
      stroke: var(--ui-form-input-date_picker-focused-icon-color);
    }
  }

  &.sunday-component-picker-disabled {
    border-color: var(--ui-form-input-date_picker-disabled-border-color);
    background-color: var(
      --ui-form-input-date_picker-disabled-background-color
    );
    input {
      color: var(--ui-form-input-date_picker-disabled-fields_date_picker-color);
      ::placeholder {
        color: var(
          --ui-form-input-date_picker-disabled-placeholder_date_picker-color
        );
      }
    }
    span.sunday-component-picker-suffix {
      color: var(--ui-form-input-date_picker-disabled-icon-color);
      stroke: var(--ui-form-input-date_picker-disabled-icon-color);
    }
  }
`;

const DatePickerGlobalStyle = createGlobalStyle`
  .sunday-component-picker-panel {
    .sunday-component-picker-header .sunday-component-picker-header-view button:hover {
      color: var(--ui-form-button-text-enabled-text-color);
    }
    .sunday-component-picker-body {
      .sunday-component-picker-cell-selected .sunday-component-picker-cell-inner{
        color: var(--ui-form-calendar-selected-text-color);
        background-color: var(--ui-form-calendar-selected-background-color);
      }
      .sunday-component-picker-cell-today:not(.sunday-component-picker-cell-disabled) .sunday-component-picker-cell-inner{
        color: var(--ui-form-calendar-today-text-color);
        background-color: var(--ui-form-calendar-today-background-color);
        :before {
          border-color: var(--ui-form-calendar-today-border-color);
        }
      }
    } 
  }
`;

export interface DatePickerInputProp {
  value?: Moment;
  form?: FormInstance;
  dataTestId?: string;
  format?: string;
  placeholder?: string;
  disabledPast?: boolean;
  disabledToday?: boolean;
  disabledFuture?: boolean;
  disabled?: boolean;
  allowClear?: boolean;
  onSelect?: (value: Moment) => void;
  onChange?: (value: Moment) => void;
  disabledDate?: (value: Moment) => boolean;
  defaultPickerValue?: Moment;
}

export const defaultDisabledDate =
  ({ disabledPast, disabledToday, disabledFuture }) =>
  (date) =>
    some([
      disabledPast && isBeforeDate(date),
      disabledToday && isToday(date),
      disabledFuture && isAfterDate(date),
    ]);

const DatePickerInput = ({
  value,
  form,
  format = DEFAULT_DATE_FORMAT,
  dataTestId,
  placeholder,
  disabledPast,
  disabledToday,
  disabledFuture,
  disabled = false,
  allowClear = false,
  onSelect,
  onChange,
  disabledDate,
  defaultPickerValue,
  ...props
}: DatePickerInputProp) => (
  <>
    <DatePickerGlobalStyle />
    <CustomDatePicker
      value={value}
      data-testid={dataTestId}
      data-gtm-input-code={dataTestId}
      format={format}
      placeholder={placeholder}
      disabled={disabled}
      disabledDate={
        disabledDate ||
        defaultDisabledDate({
          disabledPast,
          disabledToday,
          disabledFuture,
        })
      }
      allowClear={allowClear}
      onSelect={onSelect}
      onChange={onChange}
      inputReadOnly={isMobile}
      defaultPickerValue={defaultPickerValue}
      {...props}
    />
  </>
);

export default DatePickerInput;
