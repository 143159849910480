import {
  ChangeEventHandler,
  ClipboardEventHandler,
  KeyboardEventHandler,
  MouseEventHandler,
} from "react";
import styled from "styled-components";
import { TextBox as SundayTextBox } from "sunday-component";

export const CustomTextBox = styled(SundayTextBox)`
  &&& {
    & {
      input {
        color: var(--ui-form-input-text-activated-fields_text-color);
        caret-color: var(--ui-form-input-text-activated-fields_text-color);
        background-color: var(--ui-form-input-text-activated-background-color);
        ::placeholder {
          color: var(--ui-form-input-text-activated-placeholder_text-color);
          background-color: var(
            --ui-form-input-text-activated-background-color
          );
        }
      }
      background-color: var(--ui-form-input-text-activated-background-color);
      border-color: var(--ui-form-input-text-activated-border-color);
    }

    &.sunday-component-input-affix-wrapper-focused {
      input {
        background-color: var(--ui-form-input-text-focused-background-color);
        ::placeholder {
          color: var(--ui-form-input-text-focused-placeholder_text-color);
          background-color: var(--ui-form-input-text-focused-background-color);
        }
      }
      background-color: var(--ui-form-input-text-focused-background-color);
      border-color: var(--ui-form-input-text-focused-border-color);
      box-shadow: 0 0 0 2px var(--ui-form-input-text-focused-border-shadow);
    }

    &:not(.sunday-component-input-affix-wrapper-disabled) {
      :hover {
        input {
          background-color: var(--ui-form-input-text-hover-background-color);
          ::placeholder {
            color: var(--ui-form-input-text-hover-placeholder_text-color);
            background-color: var(--ui-form-input-text-hover-background-color);
          }
        }
        background-color: var(--ui-form-input-text-hover-background-color);
        border-color: var(--ui-form-input-text-hover-border-color);
      }
    }

    &.sunday-component-input-affix-wrapper-disabled {
      input {
        color: var(--ui-form-input-text-disabled-fields_text-color);
        background-color: var(--ui-form-input-text-disabled-background-color);
        ::placeholder {
          color: var(--ui-form-input-text-disabled-placeholder_text-color);
          background-color: var(--ui-form-input-text-disabled-background-color);
        }
      }
      background-color: var(--ui-form-input-text-disabled-background-color);
      border-color: var(--ui-form-input-text-disabled-border-color);
    }
  }
`;

const disabledNumberScroll = (event) => {
  event.currentTarget.blur();
};

export type TextBoxProp = {
  dataTestId?: string;
  size?: "large" | "middle" | "small";
  placeholder?: string;
  defaultValue?: string;
  disabled?: boolean;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  onPaste?: ClipboardEventHandler<HTMLInputElement>;
  onClick?: MouseEventHandler<HTMLInputElement>;
  onWheel?: MouseEventHandler<HTMLInputElement>;
  onKeyPress?: KeyboardEventHandler<HTMLInputElement>;
  type?: string;
  suffix?: React.ReactNode;
  value?: string;
};

const TextBox = ({
  dataTestId = "",
  size = "large",
  placeholder = "",
  defaultValue = null,
  disabled = false,
  onChange = () => {},
  onClick = () => {},
  onWheel = () => {},
  onKeyPress = () => {},
  onPaste = () => {},
  suffix = <></>,
  value,
  ...prop
}: TextBoxProp) => (
  <CustomTextBox
    data-testid={dataTestId}
    data-gtm-input-code={dataTestId}
    size={size}
    placeholder={placeholder}
    defaultValue={defaultValue}
    onChange={onChange}
    onClick={onClick}
    onKeyPress={onKeyPress}
    onWheel={prop?.type === "number" ? disabledNumberScroll : () => {}}
    disabled={disabled}
    suffix={suffix}
    value={value}
    onPaste={onPaste}
    {...prop}
  />
);

export default TextBox;
