import styled from "styled-components";
import { Form as SundayForm } from "sunday-component";
import { AutoCompleteErrorCss } from "./AutoCompleteForm";
import { CheckBoxErrorCss } from "./CheckBoxForm";
import { ChipGroupErrorCss } from "./ChipGroupForm";
import { DatePickerErrorCss } from "./DatePickerForm";
import { RadioGroupErrorCss } from "./RadioGroupForm";
import { TextAreaErrorCss } from "./TextAreaForm";
import { TextBoxErrorCss } from "./TextBoxForm";

const Form = styled(SundayForm)`
  & {
    label {
      color: var(--ui-form-input-text-activated-label_text-color) !important;
    }
  }
  .sunday-component-form-item-with-help {
    label {
      color: var(--ui-form-input-text-error-label_text-color) !important;
    }
  }
  label.sunday-component-form-item-required::before {
    color: var(--ui-form-input-text-error-helper_text-color) !important;
  }
  .sunday-component-form-item-explain {
    color: var(--ui-form-input-text-error-helper_text-color) !important;
  }
  .sunday-component-form-item-has-error {
    ${TextBoxErrorCss}
    ${AutoCompleteErrorCss}
    ${DatePickerErrorCss}
    ${TextAreaErrorCss}
    ${ChipGroupErrorCss}
    ${RadioGroupErrorCss}
    ${CheckBoxErrorCss}
  }
`;

export default Form;
