import styled, { css } from "styled-components";
import { Body, Title } from "sunday-component";
import ChipGroup, { ChipGroupProp } from "../ChipGroup";

export const ChipGroupLabelText = styled(Title).attrs({ level: 7 })`
  color: var(--ui-form-chip-label-enabled-label_chip-color);
`;

export const ChipGroupErrorText = styled(Body).attrs({ level: 7 })`
  color: var(--ui-form-chip-helper-error-helper_chip-color);
`;

export const ChipGroupHelperText = styled(Body).attrs({ level: 7 })`
  color: var(--ui-form-chip-helper-enabled-helper_chip-color);
`;

export const ChipGroupErrorCss = css`
  ${ChipGroupLabelText} {
    color: var(--ui-form-chip-label-error-label_chip-color);
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export interface ChipGroupFormProps extends ChipGroupProp {
  label?: string;
  helperText?: string;
  errorText?: string;
  isError?: boolean;
}

const ChipGroupForm = ({
  label,
  helperText,
  errorText,
  disabled = false,
  isError = false,
  ...props
}: ChipGroupFormProps) => {
  return (
    <Container>
      <ChipGroupLabelText>{label}</ChipGroupLabelText>
      <ChipGroup disabled={disabled} {...props} />
      {isError && <ChipGroupErrorText>{errorText}</ChipGroupErrorText>}
      <ChipGroupHelperText>{helperText}</ChipGroupHelperText>
    </Container>
  );
};

export default ChipGroupForm;
