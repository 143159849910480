import styled from "styled-components";
import { RadioGroup as SundayRadioGroup } from "sunday-component";

const RadioGroup = styled(SundayRadioGroup)`
  background: white;
  .sunday-component-radio-wrapper {
    .sunday-component-radio-checked {
      :after {
        border: 0px;
      }
    }

    .sunday-component-radio-inner {
      background-color: var(
        --ui-form-radio_buttons-unselected-enabled-background-color
      );
      border-color: var(
        --ui-form-radio_buttons-unselected-enabled-border-color
      );
      box-shadow: none;
      :after {
        transition: none;
      }
    }

    :not(.sunday-component-radio-wrapper-disabled) {
      :hover {
        .sunday-component-radio-inner {
          background-color: var(
            --ui-form-radio_buttons-unselected-hover-background-color
          );
          border-color: var(
            --ui-form-radio_buttons-unselected-hover-border-color
          );
        }
      }
      :focus-within {
        .sunday-component-radio-inner {
          background-color: var(
            --ui-form-radio_buttons-unselected-focused-background-color
          );
          border-color: var(
            --ui-form-radio_buttons-unselected-focused-border-color
          );
        }
      }
      :active {
        .sunday-component-radio-inner {
          background-color: var(
            --ui-form-radio_buttons-unselected-pressed-background-color
          );
          border-color: var(
            --ui-form-radio_buttons-unselected-pressed-border-color
          );
        }
      }
    }
  }

  .sunday-component-radio-disabled .sunday-component-radio-inner {
    background-color: var(
      --ui-form-radio_buttons-unselected-disabled-background-color
    );
    border-color: var(--ui-form-radio_buttons-unselected-disabled-border-color);
  }

  .sunday-component-radio-wrapper-checked {
    .sunday-component-radio-inner {
      background-color: var(
        --ui-form-radio_buttons-selected-enabled-background-color
      );
      border-color: var(
        --ui-form-radio_buttons-selected-enabled-background-color
      );

      :after {
        background-color: var(
          --ui-form-radio_buttons-selected-enabled-icon-color
        );
      }
    }

    :not(.sunday-component-radio-wrapper-disabled) {
      :hover {
        .sunday-component-radio-inner {
          background-color: var(
            --ui-form-radio_buttons-selected-hover-background-color
          );
          border-color: var(
            --ui-form-radio_buttons-selected-hover-background-color
          );
          :after {
            background-color: var(
              --ui-form-radio_buttons-selected-hover-icon-color
            );
          }
        }
      }
      :focus-within {
        .sunday-component-radio-inner {
          background-color: var(
            --ui-form-radio_buttons-selected-focused-background-color
          );
          border-color: var(
            --ui-form-radio_buttons-selected-focused-background-color
          );
          :after {
            background-color: var(
              --ui-form-radio_buttons-selected-focused-icon-color
            );
          }
        }
      }
      :active {
        .sunday-component-radio-inner {
          background-color: var(
            --ui-form-radio_buttons-selected-pressed-background-color
          );
          border-color: var(
            --ui-form-radio_buttons-selected-pressed-background-color
          );
          :after {
            background-color: var(
              --ui-form-radio_buttons-selected-pressed-icon-color
            );
          }
        }
      }
    }
  }

  .sunday-component-radio-checked.sunday-component-radio-disabled
    .sunday-component-radio-inner {
    background-color: var(
      --ui-form-radio_buttons-selected-disabled-background-color
    );
    border-color: var(
      --ui-form-radio_buttons-selected-disabled-background-color
    );
    :after {
      background-color: var(
        --ui-form-radio_buttons-selected-disabled-icon-color
      );
    }
  }
`;

export default RadioGroup;
