import styled, { css } from "styled-components";
import { Title, Body } from "sunday-component";
import AutoComplete, {
  AutoCompleteProp,
  CustomDropdown,
} from "../AutoComplete";

export const AutoCompleteLabelText = styled(Title).attrs({ level: 7 })`
  color: var(--ui-form-input-select-activated-label_select-color);
`;
export const AutoCompleteErrorText = styled(Body).attrs({ level: 7 })`
  color: var(--ui-form-input-select-error-helper_select-color);
`;

export const AutoCompleteHelperText = styled(Body).attrs({ level: 7 })`
  color: var(--ui-form-input-select-activated-helper_select-color);
`;

export const AutoCompleteErrorCss = css`
  ${AutoCompleteLabelText} {
    color: var(--ui-form-input-select-error-label_select-color);
  }
  ${CustomDropdown}.sunday-component-select-show-search.sunday-component-select:not(.sunday-component-select-customize-input):not(.sunday-component-select-disabled) {
    .sunday-component-select-selector {
      background-color: var(--ui-form-input-select-error-background-color);
      border-color: var(--ui-form-input-select-error-border-color) !important;
    }

    .sunday-component-select-selection-placeholder {
      color: var(--ui-form-input-select-error-placeholder_select-color);
    }
    .sunday-component-select-arrow {
      color: var(--ui-form-input-select-error-icon-color);
    }
  }
`;

const Container = styled.div.attrs(
  (props: { disabled: boolean; isError: boolean }) => props
)`
  display: flex;
  flex-direction: column;
  gap: 4px;

  ${(props) =>
    !props.disabled &&
    !props.isError &&
    `:hover {
    ${AutoCompleteLabelText} {
      color: var(--ui-form-input-select-hover-label_select-color);
    }
    ${AutoCompleteHelperText} {
      color: var(--ui-form-input-select-hover-helper_select-color);
    }
  }

  :focus-within {
    ${AutoCompleteLabelText} {
      color: var(--ui-form-input-select-focused-label_select-color);
    }
    ${AutoCompleteHelperText} {
      color: var(--ui-form-input-select-focused-helper_select-color);
    }
  }`}

  ${(props) => props.isError && AutoCompleteErrorCss}

  ${(props) =>
    props.disabled &&
    `
  ${AutoCompleteLabelText} {
    color: var(--ui-form-input-select-disabled-label_select-color);
  }
  ${AutoCompleteHelperText} {
    color: var(--ui-form-input-select-disabled-helper_select-color);
  }
  `}
`;
export interface AutoCompleteFormProps extends AutoCompleteProp {
  label?: string;
  helperText?: string;
  errorText?: string;
  isError?: boolean;
}

const AutoCompleteForm = ({
  label,
  placeholder,
  helperText,
  errorText,
  disabled = false,
  isError = false,
  ...props
}: AutoCompleteFormProps) => {
  return (
    <Container disabled={disabled} isError={isError}>
      <AutoCompleteLabelText>{label}</AutoCompleteLabelText>
      <AutoComplete placeholder={placeholder} disabled={disabled} {...props} />
      {isError && <AutoCompleteErrorText>{errorText}</AutoCompleteErrorText>}
      <AutoCompleteHelperText>{helperText}</AutoCompleteHelperText>
    </Container>
  );
};

export default AutoCompleteForm;
