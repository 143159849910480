import { color } from "src/styles/globals";
import styled, { css } from "styled-components";
import { Body, Title } from "sunday-component";
import TextArea, { CustomTextArea, TextAreaProp } from "../TextArea";

export const TextBoxOptionalText = styled(Body).attrs({ level: 7 })`
  display: inline;
  // move color to config later
  color: ${color.charcoal07};
`;

export const TextAreaLabelText = styled(Title).attrs({ level: 7 })`
  color: var(--ui-form-input-text_area-activated-label_text_area-color);
`;
export const TextAreaErrorText = styled(Body).attrs({ level: 7 })`
  color: var(--ui-form-input-text_area-error-helper_text_area-color);
`;

export const TextAreaHelperText = styled(Body).attrs({ level: 7 })`
  color: var(--ui-form-input-text_area-activated-helper_text_area-color);
`;

export const TextAreaErrorCss = css`
  ${TextAreaLabelText} {
    color: var(--ui-form-input-text_area-error-label_text_area-color);
  }
  ${CustomTextArea}:not(.sunday-component-input-disabled) {
    background-color: var(--ui-form-input-text_area-error-background-color);
    color: var(--ui-form-input-text_area-activated-fields_text_area-color);
    border-color: var(--ui-form-input-text_area-error-border-color);
    ::placeholder {
      color: var(--ui-form-input-text_area-error-placeholder_text_area-color);
    }
  }
`;

const Container = styled.div.attrs(
  (props: { disabled: boolean; isError: boolean }) => props
)`
  display: flex;
  flex-direction: column;
  gap: 4px;

  ${(props) =>
    !props.disabled &&
    `:hover {
    ${TextAreaLabelText} {
      color: var(--ui-form-input-text_area-hover-label_text_area-color);
    }
    ${TextAreaHelperText} {
      color: var(--ui-form-input-text_area-hover-helper_text_area-color);
    }
  }

  :focus-within {
    ${TextAreaLabelText} {
      color: var(--ui-form-input-text_area-focused-label_text_area-color);
    }
    ${TextAreaHelperText} {
      color: var(--ui-form-input-text_area-focused-helper_text_area-color);
    }
  }`}

  ${(props) => props.isError && TextAreaErrorCss}

  ${(props) =>
    props.disabled &&
    `
  ${TextAreaLabelText} {
    color: var(--ui-form-input-text_area-disabled-label_text_area-color);
  }
  ${TextAreaHelperText} {
    color: var(--ui-form-input-text_area-disabled-helper_text_area-color);
  }
  `}
`;

export interface TextAreaFormProps extends TextAreaProp {
  label?: string;
  helperText?: string;
  errorText?: string;
  isError?: boolean;
  optionalText?: string;
}

const TextAreaForm = ({
  label,
  helperText,
  errorText,
  disabled = false,
  isError = false,
  optionalText,
  ...props
}: TextAreaFormProps) => {
  return (
    <Container disabled={disabled} isError={isError}>
      <TextAreaLabelText>
        {label}{" "}
        {optionalText && (
          <TextBoxOptionalText>{optionalText}</TextBoxOptionalText>
        )}
      </TextAreaLabelText>
      <TextArea disabled={disabled} {...props} />
      {isError && <TextAreaErrorText>{errorText}</TextAreaErrorText>}
      <TextAreaHelperText>{helperText}</TextAreaHelperText>
    </Container>
  );
};

export default TextAreaForm;
