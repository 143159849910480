import { Chip as SundayChip } from "sunday-component";
import { MouseEventHandler } from "react";
import styled from "styled-components";

export type ChipProp = {
  label: string | JSX.Element;
  activated: boolean;
  onClick: MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  chipType?: "normal" | "choice";
  width?: string | number;
};

export const CustomChip = styled(SundayChip)`
  &[class*="normal"] {
    &[class*="clickable"] {
      * {
        color: var(--ui-form-chip-primary-enabled-text-color);
      }
      background-color: var(--ui-form-chip-primary-enabled-background-color);
      border-color: var(--ui-form-chip-primary-enabled-border-color);
      ::before {
        opacity: 0;
      }
    }
    &[class*="clickable"]:not([class*="activated"], [class*="disabled"]) {
      :hover {
        * {
          color: var(--ui-form-chip-primary-hover-text-color);
        }
        background-color: var(--ui-form-chip-primary-hover-background-color);
        border-color: var(--ui-form-chip-primary-hover-border-color);
      }

      :focus {
        * {
          color: var(--ui-form-chip-primary-focused-text-color);
        }
        background-color: var(--ui-form-chip-primary-focused-background-color);
        border-color: var(--ui-form-chip-primary-focused-border-color);
      }

      :active {
        * {
          color: var(--ui-form-chip-primary-pressed-text-color);
        }
        background-color: var(--ui-form-chip-primary-pressed-background-color);
        border-color: var(--ui-form-chip-primary-pressed-border-color);
      }
    }
    && {
      &[class*="activated"] {
        * {
          color: var(--ui-form-chip-primary-activated-text-color);
        }
        background-color: var(
          --ui-form-chip-primary-activated-background-color
        );
        border-color: var(--ui-form-chip-primary-activated-background-color);
      }

      &[class*="disabled"] {
        * {
          color: var(--ui-form-chip-primary-disabled-text-color);
        }
        background-color: var(--ui-form-chip-primary-disabled-background-color);
        border-color: var(--ui-form-chip-primary-disabled-border-color);
      }

      &[class*="activated"][class*="disabled"] {
        * {
          color: var(--ui-form-chip-primary-activated_disabled-text-color);
        }
        background-color: var(
          --ui-form-chip-primary-activated_disabled-background-color
        ) !important;
        border-color: var(
          --ui-form-chip-primary-activated_disabled-background-color
        ) !important;
      }
    }
  }
`;

const Chip = ({
  label,
  activated,
  onClick,
  disabled,
  chipType,
  width,
  ...props
}: ChipProp) => {
  return (
    <CustomChip
      leadingIcon=""
      trailingIcon=""
      clickabled
      activated={activated}
      disabled={disabled}
      fadeOnFocus={false}
      fadeOnHover={false}
      fullWidth={false}
      type="button"
      chipType={chipType}
      onClick={onClick}
      width={width}
      {...props}
    >
      {label}
    </CustomChip>
  );
};

export default Chip;
