import { FormInstance } from "sunday-component/node_modules/antd";
import { some } from "lodash";
import { isMobile } from "src/utils/deviceDetector";
import moment, { Moment } from "moment";
import {
  DATE_FORMAT,
  isAfterDate,
  isBeforeDate,
  isToday,
} from "src/utils/date";
import { CustomDatePicker, DatePickerGlobalStyle } from "./DatePicker.styled";

export interface DatePickerInputProp {
  value?: Date | Moment | string;
  form?: FormInstance;
  dataTestId?: string;
  format?: string;
  placeholder?: string;
  disabledPast?: boolean;
  disabledToday?: boolean;
  disabledFuture?: boolean;
  disabled?: boolean;
  allowClear?: boolean;
  onChange?: (value: Moment) => void;
  disabledDate?: (value: Moment | Date) => boolean;
  defaultPickerValue?: Date | Moment | string;
  className?: string;
  style?: object;
}

export const defaultDisabledDate =
  ({ disabledPast, disabledToday, disabledFuture }) =>
  (date) =>
    some([
      disabledPast && isBeforeDate(date),
      disabledToday && isToday(date),
      disabledFuture && isAfterDate(date),
    ]);

const DatePicker = ({
  value,
  form,
  format = DATE_FORMAT.dMMMMyyyy,
  dataTestId,
  placeholder,
  disabledPast,
  disabledToday,
  disabledFuture,
  disabled = false,
  allowClear = false,
  onChange,
  disabledDate,
  defaultPickerValue,
  className,
  ...props
}: DatePickerInputProp) => (
  <>
    <DatePickerGlobalStyle />
    <CustomDatePicker
      value={(value as any as string) || (defaultPickerValue as any as string)}
      data-testid={dataTestId}
      data-gtm-input-code={dataTestId}
      format={format}
      placeholder={placeholder}
      disabled={disabled}
      filterDates={
        disabledDate
          ? (v) => !disabledDate(v)
          : (v) =>
              !defaultDisabledDate({
                disabledPast,
                disabledToday,
                disabledFuture,
              })(v)
      }
      onChange={(v) => onChange(moment(v))}
      className={className}
      {...{ autocomplete: "off" }} // Dev-note: This is work around for prevent lint error because component dont provide this prop.
      {...(isMobile ? { onFocus: (e) => e.target.blur() } : {})} // Dev-note: This is work around for disable keyboard on mobile device.
      {...props}
    />
  </>
);

export default DatePicker;
