import styled, { createGlobalStyle } from "styled-components";
import { DatePicker } from "sunday-component";

export const CustomDatePicker = styled(DatePicker)`
  &.react-datepicker-popper {
    z-index: 100;
    &[class*="sundayDatePicker"] {
      /* active, hover button */
      [class*="panel"] {
        [class*="active"],
        button:hover {
          color: var(--ui-form-calendar-selected-text-color);
          background-color: var(--ui-form-calendar-selected-background-color);
        }
      }
      /* active, hover header selection */
      [class*="header"] {
        [class*="selector"][class*="selected"] {
          border-color: var(--ui-form-calendar-selected-background-color);
          [class*="arrow"],
          [class*="buttonText"],
          [class*="BE"] {
            border-color: var(--ui-form-calendar-selected-background-color);
            color: var(--ui-form-calendar-selected-background-color);
          }
        }
      }
      /* selected day */
      .react-datepicker__day--selected {
        color: var(--ui-form-calendar-selected-text-color);
        background-color: var(--ui-form-calendar-selected-background-color);
      }
    }
  }
`;
export const DatePickerGlobalStyle = createGlobalStyle`
  .react-datepicker-wrapper {
    .react-datepicker__input-container {
      .sunday-component-input-affix-wrapper:not(.sunday-component-input-affix-wrapper-disabled) {
        &:hover {
          border-color: var(--ui-form-input-date_picker-focused-border-color);
        }
      }
      .sunday-component-input-affix-wrapper-focused {
        box-shadow: 0px 0px 0px 2px var(--ui-form-input-date_picker-focused-border-shadow) ;
      }
      .sunday-component-input-affix-wrapper-disabled {
        .sunday-component-input-disabled {
          color: #76777A;
        }
      }
    }
  }
`;
