import styled, { css } from "styled-components";
import { Body, Title } from "sunday-component";
import CheckBox, { CheckBoxProp } from "../CheckBox";

export const CheckBoxLabelText = styled(Title).attrs({ level: 7 })`
  color: var(--ui-form-checkboxes-label-enabled-label_checkboxes-color);
`;

export const CheckBoxErrorText = styled(Body).attrs({ level: 7 })`
  color: var(--ui-form-checkboxes-helper-error-helper_checkboxes-color);
`;

export const CheckBoxHelperText = styled(Body).attrs({ level: 7 })`
  color: var(--ui-form-checkboxes-helper-enabled-helper_checkboxes-color);
`;

export const CheckBoxErrorCss = css`
  ${CheckBoxLabelText} {
    color: var(--ui-form-checkboxes-label-error-label_checkboxes-color);
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export interface CheckBoxFormProps extends CheckBoxProp {
  label?: string | JSX.Element;
  helperText?: string;
  errorText?: string;
  isError?: boolean;
}

const CheckBoxForm = ({
  label,
  helperText,
  errorText,
  disabled = false,
  isError = false,
  ...props
}: CheckBoxFormProps) => {
  return (
    <Container>
      <CheckBox
        label={<CheckBoxLabelText>{label}</CheckBoxLabelText>}
        disabled={disabled}
        {...props}
      />
      {isError && <CheckBoxErrorText>{errorText}</CheckBoxErrorText>}
      <CheckBoxHelperText>{helperText}</CheckBoxHelperText>
    </Container>
  );
};

export default CheckBoxForm;
