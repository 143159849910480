import styled, { css } from "styled-components";
import { Body, Title } from "sunday-component";
import RadioGroup from "../RadioGroup";

export const RadioGroupLabelText = styled(Title).attrs({ level: 7 })`
  color: var(--ui-form-radio_buttons-label-enabled-label_radio_buttons-color);
`;

export const RadioGroupErrorText = styled(Body).attrs({ level: 7 })`
  color: var(--ui-form-radio_buttons-helper-error-helper_radio_buttons-color);
`;

export const RadioGroupHelperText = styled(Body).attrs({ level: 7 })`
  color: var(--ui-form-radio_buttons-helper-enabled-helper_radio_buttons-color);
`;

export const RadioGroupErrorCss = css`
  ${RadioGroupLabelText} {
    color: var(--ui-form-radio_buttons-label-error-label_radio_buttons-color);
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export interface RadioGroupFormProps {
  label?: string;
  helperText?: string;
  errorText?: string;
  isError?: boolean;
  disabled?: boolean;
}

const RadioGroupForm = ({
  label,
  helperText,
  errorText,
  disabled = false,
  isError = false,
  ...props
}: RadioGroupFormProps) => {
  return (
    <Container>
      <RadioGroupLabelText>{label}</RadioGroupLabelText>
      <RadioGroup disabled={disabled} {...props} />
      {isError && <RadioGroupErrorText>{errorText}</RadioGroupErrorText>}
      <RadioGroupHelperText>{helperText}</RadioGroupHelperText>
    </Container>
  );
};

export default RadioGroupForm;
