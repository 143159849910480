import moment, { Moment } from "moment";
import { DATE_INPUT_TEXT_FORMAT } from "src/constants/format";

export const DEFAULT_DATE_FORMAT = "DD/MM/YYYY";
export const DATE_FORMAT = {
  ddMMyyyy: "dd/MM/yyyy",
  dMMMMyyyy: "d MMMM yyyy",
};
export const DATE_LONG_FORMAT = "YYYY-MM-DDTHH:mm:ssZ";

export const getNow = () => moment();
export const getCurrentDate = () => getNow().startOf("day");

export const parseDate = (date, format = DEFAULT_DATE_FORMAT) => {
  if (moment.isMoment(date)) {
    return date;
  }

  const newDate = moment(date, format);
  if (newDate.isValid()) {
    return newDate;
  }

  throw new TypeError("Given date is invalid.");
};

export const formatDateText = (
  date: Moment,
  dateFormat: string = DATE_INPUT_TEXT_FORMAT
) => {
  if (date.isValid()) {
    return moment(date).format(dateFormat);
  }

  throw new TypeError("Given date is invalid.");
};

export const isBeforeDate = (input, date = getCurrentDate()) => {
  try {
    return date.isAfter(parseDate(input).endOf("day"));
  } catch {
    return false;
  }
};

export const isAfterDate = (input, date = getCurrentDate()) => {
  try {
    return date.isBefore(parseDate(input).startOf("day"));
  } catch {
    return false;
  }
};

export const isBetweenDate = ({
  from,
  to,
  date = getCurrentDate(),
  edgeCase = false,
  format = DEFAULT_DATE_FORMAT,
}) => {
  try {
    const parsedDate = parseDate(date);
    if (edgeCase) {
      return (
        parsedDate.isSameOrAfter(moment(from, format).startOf("day")) &&
        parsedDate.isSameOrBefore(moment(to, format).endOf("day"))
      );
    }
    return parsedDate.isBetween(from, to);
  } catch {
    return false;
  }
};

export const isToday = (currentDate) =>
  currentDate && getNow().isSame(currentDate, "day");
