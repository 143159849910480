import { FormInstance } from "sunday-component/node_modules/antd";
import styled, { createGlobalStyle } from "styled-components";
import { Dropdown } from "sunday-component";
import { SelectOptionType } from "src/types/selectOption";

export const CustomDropdown = styled(Dropdown)`
  &.sunday-component-select-show-search.sunday-component-select:not(.sunday-component-select-customize-input) {
    .sunday-component-select-selector {
      color: var(--ui-form-input-select-activated-fields_select-color);
      background-color: var(--ui-form-input-select-activated-background-color);
      border-color: var(--ui-form-input-select-activated-border-color);
      height: 48px;
      border-radius: 8px;
      padding-top: 4px;
    }

    .sunday-component-select-selection-placeholder {
      color: var(--ui-form-input-select-activated-placeholder_select-color);
    }
    .sunday-component-select-arrow {
      color: var(--ui-form-input-select-activated-icon-color);
    }

    :not(.sunday-component-select-disabled) :hover {
      .sunday-component-select-selector {
        background-color: var(--ui-form-input-select-hover-background-color);
        border-color: var(--ui-form-input-select-hover-border-color);
        height: 48px;
        border-radius: 8px;
        padding-top: 4px;
      }

      .sunday-component-select-selection-placeholder {
        color: var(--ui-form-input-select-hover-placeholder_select-color);
      }

      .sunday-component-select-arrow {
        color: var(--ui-form-input-select-hover-icon-color);
      }
    }
  }

  &.sunday-component-select-focused.sunday-component-select-show-search.sunday-component-select:not(.sunday-component-select-customize-input) {
    .sunday-component-select-selector {
      background-color: var(--ui-form-input-select-focused-background-color);
      border-color: var(--ui-form-input-select-focused-border-color);
      box-shadow: 0 0 0 2px var(--ui-form-input-select-focused-border-shadow);
      height: 48px;
      border-radius: 8px;
      padding-top: 4px;
    }

    .sunday-component-select-selection-placeholder {
      color: var(--ui-form-input-select-focused-placeholder_select-color);
    }

    .sunday-component-select-arrow {
      color: var(--ui-form-input-select-focused-icon-color);
    }
  }

  &.sunday-component-select-disabled.sunday-component-select:not(.sunday-component-select-customize-input) {
    .sunday-component-select-selector {
      color: var(--ui-form-input-select-disabled-fields_select-color);
      background-color: var(--ui-form-input-select-disabled-background-color);
      border-color: var(--ui-form-input-select-disabled-border-color);
      height: 48px;
      border-radius: 8px;
      padding-top: 4px;
    }

    .sunday-component-select-selection-placeholder {
      color: var(--ui-form-input-select-disabled-placeholder_select-color);
    }

    .sunday-component-select-arrow {
      color: var(--ui-form-input-select-disabled-icon-color);
    }
  }

  height: 48px;
`;

const AutoCompleteGlobalStyle = createGlobalStyle`
  .sunday-component-select-dropdown {
    .rc-virtual-list-holder-inner{
      .sunday-component-select-item{
        color: var(--ui-form-dropdown-unselect-enabled-text-color);
        background-color: var(--ui-form-dropdown-unselect-enabled-background-color);
        border: solid 1px;
        border-bottom: 0px;
        border-color: var(--ui-form-dropdown-unselect-enabled-border-color);
        :nth-child(n+1) {
          border-color: var(--ui-form-dropdown-unselect-enabled-border-color);
        }
      }

      .sunday-component-select-item-option-active {
        color: var(--ui-form-dropdown-unselect-hover-text-color);
        background-color: var(--ui-form-dropdown-unselect-hover-background-color);
        :nth-child(n+1) {
          border-color: var(--ui-form-dropdown-unselect-hover-border-color);
        }
      }
      
      .sunday-component-select-item-option-disabled {
        color: var(--ui-form-dropdown-unselect-disabled-text-color);
        background-color: var(--ui-form-dropdown-unselect-disabled-background-color);
        :nth-child(n+1) {
          border-color: var(--ui-form-dropdown-unselect-disabled-border-color);
        }
      }

      .sunday-component-select-item-option-selected {
        color: var(--ui-form-dropdown-selected-enabled-text-color);
        background-color: var(--ui-form-dropdown-selected-enabled-background-color);
        .sunday-component-select-item-option-content {
          font-weight: var(--ui-form-dropdown-selected-enabled-text-decoration);
          text-decoration: var(--ui-form-dropdown-selected-enabled-text-decoration);
        }
        :nth-child(n+1) {
          border-color: var(--ui-form-dropdown-selected-enabled-border-color);
        }
      }

      .sunday-component-select-item-option-selected.sunday-component-select-item-option-active {
        color: var(--ui-form-dropdown-selected-hover-text-color);
        background-color: var(--ui-form-dropdown-selected-hover-background-color);
        .sunday-component-select-item-option-content {
          font-weight: var(--ui-form-dropdown-selected-hover-text-decoration);
          text-decoration: var(--ui-form-dropdown-selected-hover-text-decoration);
        }
        :nth-child(n+1) {
          border-color: var(--ui-form-dropdown-selected-hover-border-color);
        }
      }

      .sunday-component-select-item-option-selected.sunday-component-select-item-option-disabled {
        color: var(--ui-form-dropdown-selected-disabled-text-color);
        background-color: var(--ui-form-dropdown-selected-disabled-background-color);
        .sunday-component-select-item-option-content {
          font-weight: var(--ui-form-dropdown-selected-disabled-text-decoration);
          text-decoration: var(--ui-form-dropdown-selected-disabled-text-decoration);
        }
        :nth-child(n+1) {
          border-color: var(--ui-form-dropdown-selected-disabled-border-color);
        }
      }
    }
  
  }
`;

export type AutoCompleteProp = {
  value?: string;
  form?: FormInstance;
  dataTestId?: string;
  placeholder?: string;
  defaultValue?: string;
  disabled?: boolean;
  options?: SelectOptionType[];
  width?: string;
  optionFilterProp?: string;
  onChange?: (value: string | number, option: any) => void;
};

const AutoComplete = ({
  value,
  form,
  dataTestId = "",
  options = [],
  placeholder = "",
  defaultValue = null,
  disabled = false,
  width = "100%",
  optionFilterProp = "label",
  onChange = () => {},
  ...props
}: AutoCompleteProp) => (
  <>
    <AutoCompleteGlobalStyle />
    <CustomDropdown
      value={value}
      showSearch
      data-testid={dataTestId}
      data-gtm-input-code={dataTestId}
      options={options}
      placeholder={placeholder}
      defaultValue={defaultValue}
      disabled={disabled}
      style={{ width }}
      optionFilterProp={optionFilterProp}
      onChange={onChange}
      {...props}
    />
  </>
);

export default AutoComplete;
