import styled, { css } from "styled-components";
import { Body, Title } from "sunday-component";
import DatePicker from "src/components/Common/DatePicker/DatePicker";
import { CustomDatePicker, DatePickerInputProp } from "../DatePicker";

export const DatePickerLabelText = styled(Title).attrs({ level: 7 })`
  color: var(--ui-form-input-date_picker-activated-label_date_picker-color);
`;
export const DatePickerErrorText = styled(Body).attrs({ level: 7 })`
  color: var(--ui-form-input-date_picker-error-helper_date_picker-color);
`;

export const DatePickerHelperText = styled(Body).attrs({ level: 7 })`
  color: var(--ui-form-input-date_picker-activated-helper_date_picker-color);
`;

export const DatePickerErrorCss = css`
  ${DatePickerLabelText} {
    color: var(--ui-form-input-date_picker-error-label_date_picker-color);
  }
  ${CustomDatePicker}:not(.sunday-component-picker-disabled) {
    border-color: var(--ui-form-input-date_picker-error-border-color);
    background-color: var(--ui-form-input-date_picker-error-background-color);
    input {
      ::placeholder {
        color: var(
          --ui-form-input-date_picker-error-placeholder_date_picker-color
        );
      }
    }
    span.sunday-component-picker-suffix {
      color: var(--ui-form-input-date_picker-error-icon-color);
      stroke: var(--ui-form-input-date_picker-error-icon-color);
    }
  }
`;

const Container = styled.div.attrs(
  (props: { disabled: boolean; isError: boolean }) => props
)`
  display: flex;
  flex-direction: column;
  gap: 4px;

  ${(props) =>
    !props.disabled &&
    `:hover {
    ${DatePickerLabelText} {
      color: var(--ui-form-input-date_picker-hover-label_date_picker-color);
    }
    ${DatePickerHelperText} {
      color: var(--ui-form-input-date_picker-hover-helper_date_picker-color);
    }
  }

  :focus-within {
    ${DatePickerLabelText} {
      color: var(--ui-form-input-date_picker-focused-label_date_picker-color);
    }
    ${DatePickerHelperText} {
      color: var(--ui-form-input-date_picker-focused-helper_date_picker-color);
    }
  }`}

  ${(props) => props.isError && DatePickerErrorCss}

  ${(props) =>
    props.disabled &&
    `
  ${DatePickerLabelText} {
    color: var(--ui-form-input-date_picker-disabled-label_date_picker-color);
  }
  ${DatePickerHelperText} {
    color: var(--ui-form-input-date_picker-disabled-helper_date_picker-color);
  }
  `}
`;

export interface DatePickerFormProps extends DatePickerInputProp {
  label?: string;
  helperText?: string;
  errorText?: string;
  isError?: boolean;
}

const DatePickerForm = ({
  label,
  helperText,
  isError = false,
  errorText,
  disabled = false,
  ...props
}: DatePickerFormProps) => {
  return (
    <Container disabled={disabled} isError={isError}>
      <DatePickerLabelText>{label}</DatePickerLabelText>
      <DatePicker disabled={disabled} {...props} />
      {isError && <DatePickerErrorText>{errorText}</DatePickerErrorText>}
      <DatePickerHelperText>{helperText}</DatePickerHelperText>
    </Container>
  );
};

export default DatePickerForm;
