import { Row } from "antd";
import { SelectOptionType } from "src/types/selectOption";
import Chip from "./Chip";

export type ChipGroupProp = {
  value: string | number;
  options: SelectOptionType[];
  onSelect?: Function;
  onChange?: Function;
  disabled?: boolean;
};

const ChipGroup = ({
  options = [],
  value,
  onSelect = () => {},
  onChange = () => {},
  disabled = false,
  ...prop
}: ChipGroupProp) => {
  return (
    <Row {...prop} style={{ display: "flex", rowGap: "8px", columnGap: "8px" }}>
      {options.map((option) => (
        <Chip
          disabled={disabled}
          key={option.value}
          label={option.label}
          activated={value === option.value}
          onClick={() => {
            onSelect(option.value);
            onChange(option.value);
          }}
        />
      ))}
    </Row>
  );
};

export default ChipGroup;
