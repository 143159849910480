import {
  isMobile as isMobileDevice,
  isDesktop as isDesktopDevice,
} from "react-device-detect";

// DEV-NOTE: implement device detector below.
export const detectMobile = () => isMobileDevice;
export const detectDesktop = () => isDesktopDevice;

export const isMobile = detectMobile();
export const isDesktop = detectDesktop();
