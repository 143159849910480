import { ChangeEventHandler, MouseEventHandler } from "react";
import styled from "styled-components";
import { TextArea as SundayTextArea } from "sunday-component";

export const CustomTextArea = styled(SundayTextArea)`
  background-color: var(--ui-form-input-text_area-activated-background-color);
  color: var(--ui-form-input-text_area-activated-fields_text_area-color);
  caret-color: var(--ui-form-input-text_area-activated-fields_text_area-color);
  border-color: var(--ui-form-input-text_area-activated-border-color);
  ::placeholder {
    color: var(--ui-form-input-text_area-activated-placeholder_text_area-color);
  }

  :not(.sunday-component-input-disabled) {
    :hover {
      background-color: var(--ui-form-input-text_area-hover-background-color);
      border-color: var(--ui-form-input-text_area-hover-border-color);
      ::placeholder {
        color: var(--ui-form-input-text_area-hover-placeholder_text_area-color);
      }
    }
    :focus {
      background-color: var(--ui-form-input-text_area-focused-background-color);
      border-color: var(--ui-form-input-text_area-focused-border-color);
      box-shadow: 0 0 0 2px var(--ui-form-input-text_area-focused-border-shadow);
      ::placeholder {
        color: var(
          --ui-form-input-text_area-focused-placeholder_text_area-color
        );
      }
    }
  }

  &.sunday-component-input-disabled {
    color: var(--ui-form-input-text_area-disabled-fields_text_area-color);
    background-color: var(
      --ui-form-input-text_area-disabled-background-color
    ) !important;
    border-color: var(
      --ui-form-input-text_area-disabled-border-color
    ) !important;
    ::placeholder {
      color: var(
        --ui-form-input-text_area-disabled-placeholder_text_area-color
      );
    }
  }
`;

export type TextAreaProp = {
  dataTestId?: string;
  size?: "large" | "middle" | "small";
  placeholder?: string;
  defaultValue?: string;
  disabled?: boolean;
  onChange?: ChangeEventHandler<HTMLTextAreaElement>;
  onClick?: MouseEventHandler<HTMLTextAreaElement>;
  type?: string;
  autoSize?: boolean | object;
  rows?: number;
};

const TextArea = ({
  dataTestId = "",
  size = "large",
  placeholder = "",
  defaultValue = null,
  disabled = false,
  onChange = () => {},
  onClick = () => {},
  autoSize = true,
  rows = 1,
  ...prop
}: TextAreaProp) => (
  <CustomTextArea
    data-testid={dataTestId}
    data-gtm-input-code={dataTestId}
    size={size}
    placeholder={placeholder}
    defaultValue={defaultValue}
    onChange={onChange}
    onClick={onClick}
    disabled={disabled}
    autoSize={autoSize}
    rows={rows}
    {...prop}
  />
);

export default TextArea;
