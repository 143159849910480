import styled from "styled-components";
import { Button as SundayButton } from "sunday-component";

const Button = styled(SundayButton)`
  &.sunday-component-btn-primary {
    border-radius: var(--ui-form-button-primary-enabled-border-radius);
    border-color: var(--ui-form-button-primary-enabled-background-color);
    background-color: var(--ui-form-button-primary-enabled-background-color);
    * {
      color: var(--ui-form-button-primary-enabled-text-color);
    }

    &:focus {
      border-radius: var(--ui-form-button-primary-focused-border-radius);
      border-color: var(--ui-form-button-primary-focused-background-color);
      background-color: var(--ui-form-button-primary-focused-background-color);
      * {
        color: var(--ui-form-button-primary-focused-text-color);
      }
    }

    &:hover {
      border-radius: var(--ui-form-button-primary-hover-border-radius);
      border-color: var(--ui-form-button-primary-hover-background-color);
      background-color: var(--ui-form-button-primary-hover-background-color);
      * {
        color: var(--ui-form-button-primary-hover-text-color);
      }
    }

    &:disabled {
      border-radius: var(--ui-form-button-primary-disabled-border-radius);
      border-color: var(--ui-form-button-primary-disabled-background-color);
      background-color: var(--ui-form-button-primary-disabled-background-color);
      * {
        color: var(--ui-form-button-primary-disabled-text-color);
      }
    }
  }

  &.sunday-component-btn-default {
    border-radius: var(--ui-form-button-secondary-enabled-border-radius);
    background-color: var(--ui-form-button-secondary-enabled-background-color);
    border-color: var(--ui-form-button-secondary-enabled-border-color);
    * {
      color: var(--ui-form-button-secondary-enabled-text-color);
    }

    &:hover {
      border-radius: var(--ui-form-button-secondary-hover-border-radius);
      background-color: var(--ui-form-button-secondary-hover-background-color);
      border-color: var(--ui-form-button-secondary-hover-border-color);
      * {
        color: var(--ui-form-button-secondary-hover-text-color);
      }
    }
    &:focus {
      border-radius: var(--ui-form-button-secondary-focused-border-radius);
      background-color: var(
        --ui-form-button-secondary-focused-background-color
      );
      border-color: var(--ui-form-button-secondary-focused-border-color);
      * {
        color: var(--ui-form-button-secondary-focused-text-color);
      }
    }
    &:disabled {
      border-radius: var(--ui-form-button-primary-secondary-disabled-radius);
      background-color: var(--ui-form-button-secondary-disabled-background);
      border-color: var(--ui-form-button-secondary-disabled-border-color);
      * {
        color: var(--ui-form-button-secondary-disabled-text-color);
      }
    }
  }

  &.sunday-component-btn-text {
    padding: 0px;
    height: fit-content;
    width: fit-content;
    * {
      color: var(--ui-form-button-text-enabled-text-color);
    }

    &:hover {
      * {
        color: var(--ui-form-button-text-hover-text-color);
        text-decoration: var(--ui-form-button-text-hover-text-decoration);
        text-decoration-color: var(--ui-form-button-text-hover-text-color);
      }
    }
    &:focus {
      * {
        color: var(--ui-form-button-text-focused-text-color);
        text-decoration: var(--ui-form-button-text-focused-text-decoration);
        text-decoration-color: var(--ui-form-button-text-focused-text-color);
      }
    }
    &:disabled {
      * {
        color: var(--ui-form-button-text-disabled-text-color);
        text-decoration: none;
      }
    }
  }
`;

export default Button;
