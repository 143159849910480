import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { CheckBox as Cb } from "sunday-component";
import styled from "styled-components";
import { CustomCheckboxProps } from "sunday-component/lib/src/components/Input/CheckBox";

export const CustomStyledCheckBox = styled(Cb)`
  :focus-within {
    .sunday-component-checkbox:not(.sunday-component-checkbox-disabled) {
      .sunday-component-checkbox-inner {
        background-color: var(
          --ui-form-checkboxes-unselected-focused-background-color
        );
        border-color: var(--ui-form-checkboxes-unselected-focused-border-color);
      }
    }

    .sunday-component-checkbox-checked:not(.sunday-component-checkbox-disabled) {
      .sunday-component-checkbox-inner {
        background-color: var(
          --ui-form-checkboxes-selected-focused-background-color
        );
        border-color: var(--ui-form-checkboxes-selected-focused-border-color);
        :after {
          border-color: var(--ui-form-checkboxes-selected-focused-icon-color);
        }
      }
    }

    .sunday-component-checkbox-indeterminate:not(.sunday-component-checkbox-disabled) {
      .sunday-component-checkbox-inner {
        background-color: var(
          --ui-form-checkboxes-indeterminate-focused-background-color
        );
        border-color: var(
          --ui-form-checkboxes-indeterminate-focused-background-color
        );
        :after {
          background-color: var(
            --ui-form-checkboxes-indeterminate-focused-icon-color
          );
        }
      }
    }
  }
  :hover {
    .sunday-component-checkbox:not(.sunday-component-checkbox-disabled) {
      .sunday-component-checkbox-inner {
        background-color: var(
          --ui-form-checkboxes-unselected-hover-background-color
        );
        border-color: var(--ui-form-checkboxes-unselected-hover-border-color);
      }
    }

    .sunday-component-checkbox-checked:not(.sunday-component-checkbox-disabled) {
      .sunday-component-checkbox-inner {
        background-color: var(
          --ui-form-checkboxes-selected-hover-background-color
        );
        border-color: var(--ui-form-checkboxes-selected-hover-background-color);
        :after {
          border-color: var(--ui-form-checkboxes-selected-hover-icon-color);
        }
      }
      :after {
        border-color: var(--ui-form-checkboxes-selected-hover-background-color);
      }
    }

    .sunday-component-checkbox-indeterminate:not(.sunday-component-checkbox-disabled) {
      .sunday-component-checkbox-inner {
        background-color: var(
          --ui-form-checkboxes-indeterminate-hover-background-color
        );
        :after {
          background-color: var(
            --ui-form-checkboxes-indeterminate-hover-icon-color
          );
        }
      }
    }
  }

  :active {
    .sunday-component-checkbox:not(.sunday-component-checkbox-disabled) {
      .sunday-component-checkbox-inner {
        background-color: var(
          --ui-form-checkboxes-unselected-pressed-background-color
        );
        border-color: var(--ui-form-checkboxes-unselected-pressed-border-color);
      }
    }

    .sunday-component-checkbox-checked:not(.sunday-component-checkbox-disabled) {
      .sunday-component-checkbox-inner {
        background-color: var(
          --ui-form-checkboxes-selected-pressed-background-color
        );
        border-color: var(
          --ui-form-checkboxes-selected-pressed-background-color
        );
        :after {
          border-color: var(--ui-form-checkboxes-selected-pressed-icon-color);
        }
      }
    }

    .sunday-component-checkbox-indeterminate:not(.sunday-component-checkbox-disabled) {
      .sunday-component-checkbox-inner {
        background-color: var(
          --ui-form-checkboxes-indeterminate-pressed-background-color
        );
        :after {
          background-color: var(
            --ui-form-checkboxes-indeterminate-pressed-icon-color
          );
        }
      }
    }
  }

  .sunday-component-checkbox {
    .sunday-component-checkbox-inner {
      background-color: var(
        --ui-form-checkboxes-unselected-enabled-background-color
      );
      border-color: var(--ui-form-checkboxes-unselected-enabled-border-color);
    }
  }

  .sunday-component-checkbox-disabled {
    .sunday-component-checkbox-inner {
      background-color: var(
        --ui-form-checkboxes-unselected-disabled-background-color
      );
      border-color: var(
        --ui-form-checkboxes-unselected-disabled-border-color
      ) !important;
    }
  }

  .sunday-component-checkbox-checked {
    .sunday-component-checkbox-inner {
      background-color: var(
        --ui-form-checkboxes-selected-enabled-background-color
      );
      border-color: var(--ui-form-checkboxes-selected-enabled-background-color);
      :after {
        border-color: var(--ui-form-checkboxes-selected-enabled-icon-color);
      }
    }
  }

  .sunday-component-checkbox-checked.sunday-component-checkbox-disabled {
    .sunday-component-checkbox-inner {
      background-color: var(
        --ui-form-checkboxes-selected-disabled-background-color
      );
      border-color: var(
        --ui-form-checkboxes-selected-disabled-background-color
      ) !important;
      :after {
        border-color: var(--ui-form-checkboxes-selected-disabled-icon-color);
      }
    }
  }

  .sunday-component-checkbox-indeterminate {
    .sunday-component-checkbox-inner {
      background-color: var(
        --ui-form-checkboxes-indeterminate-enabled-background-color
      );
      border-color: var(
        --ui-form-checkboxes-indeterminate-enabled-background-color
      );
      :after {
        height: 2px;
        background-color: var(
          --ui-form-checkboxes-indeterminate-enabled-icon-color
        );
      }
    }
  }

  .sunday-component-checkbox-indeterminate.sunday-component-checkbox-disabled {
    .sunday-component-checkbox-inner {
      background-color: var(
        --ui-form-checkboxes-indeterminate-disabled-background-color
      );
      border-color: var(
        --ui-form-checkboxes-indeterminate-disabled-background-color
      ) !important;
      :after {
        background-color: var(
          --ui-form-checkboxes-indeterminate-disabled-icon-color
        );
      }
    }
  }
`;

export const CustomCheckBox = styled(CustomStyledCheckBox)`
  align-items: flex-start;
  display: inline-flex !important;
  .sunday-component-checkbox {
    top: 0em;
    margin: 2px;
  }
  .sunday-component-checkbox-inner {
    width: 20px !important;
    height: 20px !important;
  }
  .sunday-component-checkbox + span {
    padding: 0px;
    margin-left: 10px;
  }
`;

export type CheckBoxProp = {
  label?: string | JSX.Element;
  disabled?: boolean;
  defaultChecked?: boolean;
  dataTestId?: string;
  onChange?: (event: CheckboxChangeEvent) => void;
} & CustomCheckboxProps;

const CheckBox = ({
  label,
  disabled = false,
  defaultChecked = false,
  dataTestId,
  onChange = (event: CheckboxChangeEvent) => event,
  ...prop
}: CheckBoxProp) => {
  return (
    <CustomCheckBox
      data-testid={dataTestId}
      data-gtm-input-code={dataTestId}
      label={label}
      onChange={onChange}
      disabled={disabled}
      defaultChecked={defaultChecked}
      {...prop}
    />
  );
};

export default CheckBox;
