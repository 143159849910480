import { color } from "src/styles/globals";
import styled, { css } from "styled-components";
import { Body, Title } from "sunday-component";
import { Skeleton } from "antd";
import TextBox, { CustomTextBox, TextBoxProp } from "../TextBox";

export const TextBoxOptionalText = styled(Body).attrs({ level: 7 })`
  display: inline;
  // move color to config later
  color: ${color.charcoal07};
`;

export const TextBoxLabelText = styled(Title).attrs({ level: 7 })`
  color: var(--ui-form-input-text-activated-label_text-color);
`;
export const TextBoxErrorText = styled(Body).attrs({ level: 7 })`
  color: var(--ui-form-input-text-error-helper_text-color);
`;

export const TextBoxHelperText = styled(Body).attrs({ level: 7 })`
  color: var(--ui-form-input-text-activated-helper_text-color);
`;

export const TextBoxErrorCss = css`
  ${TextBoxLabelText} {
    color: var(--ui-form-input-text-error-label_text-color);
  }
  ${CustomTextBox}:not(.sunday-component-input-affix-wrapper-disabled) {
    border-color: var(--ui-form-input-text-error-border-color);
    background-color: var(--ui-form-input-text-error-background-color);
    input {
      backgorund-color: var(--ui-form-input-text-error-background-color);
      ::placeholder {
        color: var(--ui-form-input-text-error-placeholder_text-color);
      }
    }
  }
`;

const Container = styled.div.attrs(
  (props: { disabled: boolean; isError: boolean }) => props
)`
  display: flex;
  flex-direction: column;
  gap: 4px;

  ${(props) =>
    !props.disabled &&
    `:hover {
    ${TextBoxLabelText} {
      color: var(--ui-form-input-text-hover-label_text-color);
    }
    ${TextBoxHelperText} {
      color: var(--ui-form-input-text-hover-helper_text-color);
    }
  }

  :focus-within {
    ${TextBoxLabelText} {
      color: var(--ui-form-input-text-focused-label_text-color);
    }
    ${TextBoxHelperText} {
      color: var(--ui-form-input-text-focused-helper_text-color);
    }
  }`}

  ${(props) => props.isError && TextBoxErrorCss}

  ${(props) =>
    props.disabled &&
    `
  ${TextBoxLabelText} {
    color: var(--ui-form-input-text-disabled-label_text-color);
  }
  ${TextBoxHelperText} {
    color: var(--ui-form-input-text-disabled-helper_text-color);
  }
  `}
`;

const TextBoxSkeleton = styled(Skeleton.Input)`
  .ant-skeleton-input {
    height: 48px;
    border-radius: 8px;
  }
`;

export interface TextBoxFormProps extends TextBoxProp {
  label?: string;
  helperText?: string;
  errorText?: string;
  isError?: boolean;
  optionalText?: string;
  isLoading?: boolean;
}

const TextBoxForm = ({
  label,
  helperText,
  errorText,
  disabled = false,
  isError = false,
  optionalText,
  isLoading = false,
  ...props
}: TextBoxFormProps) => {
  return (
    <Container disabled={disabled} isError={isError}>
      <TextBoxLabelText>
        {label}{" "}
        {optionalText && (
          <TextBoxOptionalText>{optionalText}</TextBoxOptionalText>
        )}
      </TextBoxLabelText>

      {isLoading && <TextBoxSkeleton active />}
      {!isLoading && <TextBox disabled={disabled} {...props} />}
      {isError && <TextBoxErrorText>{errorText}</TextBoxErrorText>}
      <TextBoxHelperText>{helperText}</TextBoxHelperText>
    </Container>
  );
};

export default TextBoxForm;
