/* eslint-disable import/prefer-default-export */
export const color = {
  blueLandingPageBG: "#0857C3",

  carrotOrange: "#FA4616",
  carrotOrange03: "#FA4616",
  // coolGrey
  coolGreyCG: "#D9D9D6",
  coolGreyCG1: "#f7f7f7",
  coolGreyCG2: "#F0F0EF",
  coolGreyCG3: "#E8E8E6",
  coolGreyCG6: "#828280",
  coolGreyCG8: "#2B2B2B",

  // warmGrey
  warmGreyWG2: "#EDEBE8",

  // blue
  frenchBlueFb: "#307FE2",
  frenchBlue04: "#004EAB",

  white: "#fff",
  black: "#000000",

  // black
  black01: "#1D1D1D",

  // yellow
  mustardYellowMy: "#FFAD00",
  warningYellow01: "#FFF5E5",
  warningYellow03: "#FF9900",

  // black charcoal
  charcoal10: "#2A2C2E",
  charcoal09: "#54565A",
  charcoal08: "#76777A",
  charcoal07: "#98989A",
  charcoal05: "#C8C8C8",
  charcoal04: "#D9D9D6",
  charcoal03: "#E8E8E6",
  charcoal01: "#F7F7F7",

  errorRed03: "#BF0000",
  errorRed01: "#BF000019",
  // orange
  tigerOrange02: "#FF9A36",

  // pebble grey
  pebbleGrey: "#707372",

  // success green
  successGreen01: "#E8F2ED",
  successGreeen03: "#1C7C4E",

  // green
  pineGreen04: "#02685b",

  // brown
  brownTertiary: "#6F5F5E",

  // beige
  beige100: "#F1EFE3",
};
